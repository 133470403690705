import React, { Component, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu, DropdownItem
} from 'reactstrap';

import trueLogoText from '../../../assets/img/true.logo.text.webp'
import UserMenu from './UserMenu'
import { connect } from 'react-redux';

import iconTT from '../../../assets/img/icon-menu-tt.svg';
import iconMP from '../../../assets/img/icon-menu-mp.svg';
import iconTeam from '../../../assets/img/icon-menu-team.svg';
import iconBleeker from '../../../assets/img/icon-menu-bleeker.svg';
import iconContact from '../../../assets/img/icon-menu-contact.svg';
import iconPrivacy from '../../../assets/img/icon-menu-privacy.svg';


class Header extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
        }

        this.handleNav = this.handleNav.bind(this)
    }

    toggleMenu = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    }

    handleNav = (section) => {
        if (section === 'teams') {
            this.props.history.push('/teams');
        }
    }

    globalToggleMenu = () => {
        const { isOpen } = this.state;
        if (isOpen) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        const { user, history, currentUser, showTeams, systemCheck } = this.props;
        const { isOpen } = this.state;

        return (
            <header className="fixed-top bg-white">
                <Navbar expand="md" className="px-4 px-md-3">
                    <div className="container d-flex justify-content-between">
                        {
                            user.id && user.is_manager === 1 && showTeams &&
                            <NavbarToggler onClick={this.toggleMenu} className="p-0" />
                        }

                        <div className={!user.id ? 'mx-auto ml-md-0' : ''} onClick={() => currentUser()}>
                            <Link className="mr-0 navbar-brand" to={user.id ? "/home" : "/welcome"}>
                                <img src={trueLogoText} alt="True Advance" className="ml-2 d-none d-md-inline" />
                            </Link>
                        </div>

                        {systemCheck?.data?.data?.logo && <img src={systemCheck.data.data.logo} alt="Logo Account" className="ml-4 d-none d-md-inline" />}

                        <Collapse isOpen={isOpen} className="justify-content-between nav-menu mx-md-5" navbar>
                            <NavbarToggler onClick={() => this.toggleMenu} className="icon-toggler-open p-0 border-0" />
                            <Nav navbar>
                                {user.is_manager === 1 && showTeams &&
                                    <NavItem>
                                        <Link className="nav-link" to="/teams"><i className="d-md-none"><img src={iconTeam} alt="" /></i><span>Teams</span></Link>
                                    </NavItem>
                                }
                            </Nav>
                        </Collapse>

                        {
                            user.id &&
                            <UserMenu history={history} user={user} />
                        }

                    </div>
                </Navbar>
            </header>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { systemCheck } = state;
    return {
        systemCheck
    };
  };
  
  const mapDispatchToProps = {
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header);

