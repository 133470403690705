import React from 'react';
import { Col, Row } from 'reactstrap';

export const Header = ({ icon, text, show_leadership_text }) => {

    return (
        <>
            <div className="border-bottom pb-3">
                <div className="d-flex align-items-center mb-3 mb-md-0" >
                    <div className="mr-4">
                        {icon && <img src={icon} alt="new_member_discovery" width="60" />}
                    </div>
                    <div className="d-flex" >
                        <div className="mb-0 text-reset">                        
                            {show_leadership_text && 
                                <div>
                                    <b>Review your results</b><br></br>
                                    Please review your answers below and click "submit answers" to complete, or change your answers as needed before completing.<br></br>
                                </div>
                            }
                            <h6 className="text-reset">{text}</h6>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}