import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { questionnaireItemsActions } from '../../../../core/questionnaire-items/actions';

import { QuestionsList } from '../../../components/questionnaires/questions-review/QuestionsList';
import { ModalConfirmLock } from '../../../components/common/locks';
import { api } from '../../../../core/api/api-service';
import { userActions } from '../../../../core/users';
import { LEADERSHIP_QUESTIONNAIRE_CODE } from '../../../../core/constants';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const QuestionnaireReview = (params) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state);
    const questionnaire_list = useSelector(state => state.questionnaire_list.data);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    useEffect(() => {
        dispatch(questionnaireItemsActions.loadQuestionnaireItems(Number(id !== undefined ? id : params.id)));
    }, []);

    const handleConfirmSave = () => {
        if (user?.assessments_block_days) {
            setOpenModalConfirm(true);
        }
        else {
            handleSubmit();
        }
    }

    const handleSubmit = () => {
        api.fetchQuestionnaireSubmit({ id })
        .then(() => {
            dispatch(userActions.currentUser());
        });
        setOpenModalConfirm(false);
    }

    return (
        <>
            <div className="view overflow-hidden">
                <TransitionGroup className="content"></TransitionGroup>
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='questionnaire' appear>
                    <div className="h-100">
                        <Container>
                            <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 pb-md-5 pt-md-2 content-view">
                                {params?.id && params?.close && <div className="icons-funciones d-flex justify-content-end align-items-end">
                                    <a href="#" onClick={(e) => { e.preventDefault(); params.close() }} className="icon-close"></a>
                                </div>}
                                <div className="px-md-4">
                                    <div className="mb-md-4 mb-3 encabezado text-center text-md-left">
                                        <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-inline-flex d-md-flex align-items-center">
                                            <h6 className="mb-0 title-underline">{questionnaire_list?.questionnaire?.name}</h6>
                                        </div>
                                        <span className="d-none d-md-block border border-info"></span>
                                    </div>
                                    <QuestionsList
                                        questionnaire={questionnaire_list}
                                        first_time_user={!user?.questionnaire_datetime}
                                        text={questionnaire_list.questionnaire && questionnaire_list.questionnaire.detail}
                                        icon={questionnaire_list.questionnaire && questionnaire_list.questionnaire.icon} />
                                </div>

                                {questionnaire_list.questionnaire?.code === LEADERSHIP_QUESTIONNAIRE_CODE && !user?.questionnaire_datetime &&
                                    <div className="d-flex justify-content-center mt-3">
                                        <button onClick={handleConfirmSave} className="btn btn-primary px-4 rounded-pill text-uppercase rounded-pill d-flex px-md-4 ml-2">Submit Answers</button>
                                    </div>
                                }
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </div>
            <ModalConfirmLock
                isOpen={openModalConfirm}
                close={() => setOpenModalConfirm(false)}
                onAccept={handleSubmit}
                onCancel={() => setOpenModalConfirm(false)}
                blockDays={user?.assessments_block_days}
            />
        </>
    )
}