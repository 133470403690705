import { Record } from 'immutable';

export const User = new Record({
  id: null,
  avatar: null,
  isPending: false,
  email: null,
  token_id: null,
  first_name: null,
  last_name: null,
  name: null,
  account: null,
  is_manager: false,
  error: 0,
  checked: false,
  seniority_id: null,
  seniority: null,
  mind_pattern: null,
  talents: null,
  documents: null,
  account: {},
  access_pdf_tt: false,
  access_pdf_mp: false,
  accept_terms: 1,
  must_change_pwd: false,
  pwd_politics_errors: null,
  profile_story: null,
  can_download_pdf: false,
  has_tt_blocked: false,
  has_mp_blocked: false,
  has_questionnaire_blocked: false,
  assessments_block_days: 0,
  questionnaire_datetime: null,
});
