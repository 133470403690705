import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";

// import history from '../../core/history'; 
import { play, exit } from '../../core/helper'
import { QuestionItem, Start, Ready, Rearrenge, Result, Summary, Report } from '../components/thinkingtalents'
import { Container, Row, Col } from 'reactstrap';
import quest_ilustracion from '../../assets/img/iconos-01.png'
import { ModalConfirmLock } from '../components/common/locks';

import { questionsListActions, getCurrentQuestionsList } from '../../core/questions-list'
import { assessmentsListActions, getCurrentAssessmentsList, getAssessmentForCurrentAssessmentsList } from '../../core/assessments-list'
import { assessmentActions } from '../../core/assessment-item'
import { trackingActions } from '../../core/tracking';

import { DEFAULT_SESSION_ID } from '../../core/constants'

import LoadingIndicator from '../components/loading-indicator'
import { userActions } from '../../core/users';
import { Disclaimers } from '../components/common';

import ReactGA from 'react-ga';

const modes = ["out-in", "in-out"];

class ThinkingTalentsPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      current_step: 0,
      current_section: 'start',
      result_valid: false,
      next_step: false,
      total_questions: 3,
      question_index: 0,
      direction: 'right',
      animationClass: {
        appearActive: 'animated slideInRight',
        enter: 'animated',
        enterActive: 'animated slideInRight',
        exit: 'animated',
        exitActive: 'animated slideOutLeft',
      },
      response_list: new Map(),
      validations: {
        valid: true,
        group_1: {
          valid: true,
          message: ""
        },
        group_2: {
          valid: true,
          message: ""
        },
        group_3: {
          valid: true,
          message: ""
        },
      },
      response_items_by_groups: {
        group_1: new List(),
        group_2: new List(),
        group_3: new List(),
      },
      is_run_validate: false,
      rearrange_first_time: false,
      prevState: false,
      open_modal_confirm_lock: false
    }

    this.hanndleQuestionResponse = this.hanndleQuestionResponse.bind(this);
    this.hanndleNextQuestion = this.hanndleNextQuestion.bind(this);
    this.hanndlePrevQuestion = this.hanndlePrevQuestion.bind(this);
    this.hanndleValidations = this.hanndleValidations.bind(this);
    this.hanndleRearrengeResponse = this.hanndleRearrengeResponse.bind(this);
    this.getTalentsItems = this.getTalentsItems.bind(this);
    this.save = this.save.bind(this);

  }

  componentDidMount = () => {
    if(this.props.is_current_user) {
        this.props.loadAssessments()
    }
    this.props.loadQuestions()

    if(this.props.section) {
        this.hanndleNextQuestion(this.props.section)
    }

    let history_state = this.props?.history?.location?.state

    setTimeout(() => {
        if(history_state) {
            if(history_state.hanndleNextQuestion) {
                this.hanndleNextQuestion(history_state?.hanndleNextQuestion?.section, history_state?.hanndleNextQuestion?.valid)
            }
    
            // this.props.history.location.state = null;
    
        }
    }, 1000)

  }

  // componentWillUnmount() {
  //   console.log('this.props.currentUser()')
  //   this.props.currentUser();
  // }

  hanndleQuestionResponse(id, value) {
    const { response_list, current_step, response_items_by_groups } = this.state
    const { questions_list, assessment, saveAssessment } = this.props

    const item = { item_id: id, value }

    const new_response_list = response_list.set(id, item)

    const new_response_items_by_groups = Object.assign({}, response_items_by_groups, {
      ['group_' + item.value]: response_items_by_groups['group_' + item.value].push(item),
    })

    this.setState({ response_list: new_response_list, response_items_by_groups: new_response_items_by_groups });

    if (current_step !== questions_list.items.size)
      this.hanndleNextQuestion('questions');
    else {
        ReactGA.event({
            category: 'Thinking Talent',
            action: 'Creation completed'
        })
        this.props.saveTrackingEvent({category: 'Thinking Talent',action: 'Creation completed'})
      this.hanndleNextQuestion('result_1');
    }
  }

  hanndleRearrengeResponse(id, value) {
    const { response_list, current_step, validations, response_items_by_groups } = this.state;

    const old_item = response_list.get(id)
    const item = { item_id: id, value }
    const new_response_list = response_list.set(id, item)

    const new_validations = Object.assign({}, validations, {
      is_run_validate: false
    })

    const new_response_items_by_groups = Object.assign({}, response_items_by_groups, {
      ['group_' + item.value]: new List([item]).merge(response_items_by_groups['group_' + item.value]),
      ['group_' + old_item.value]: response_items_by_groups['group_' + old_item.value].filter(i => i.item_id !== old_item.item_id)
    })

    this.setState({ response_list: new_response_list, validations: new_validations, response_items_by_groups: new_response_items_by_groups });

  }

  save() {
    const { rearrange_first_time, response_list } = this.state;
    const { saveAssessment, assessment } = this.props;

    if(!rearrange_first_time) {
      ReactGA.event({
        category: 'Thinking Talent',
        action: 'Rearrange completed',
      })
    }

    this.props.saveTrackingEvent({category: 'Thinking Talent', action: 'Rearrange completed', first_time: rearrange_first_time})
    saveAssessment(assessment.id, response_list);
  }

  hanndleNextQuestion(section = 'questions', valid) {
    const { current_step, response_list, rearrange_first_time } = this.state
    const { loadQuestions, loadAssessments, saveAssessment, currentUser, user, fixSection, is_current_user, assessment } = this.props;

        if (section === 'result' && valid) {
          if(rearrange_first_time && user?.assessments_block_days) {
            this.setState({ open_modal_confirm_lock: true })
            return;
          }
        
            this.save()
            return;
        }

        if (section === "report" && is_current_user) {
        currentUser();
        console.log('----report----');
        }
        const animationClass = {
        appearActive: 'animated slideInRight position-fixed',
        enter: 'animated',
        enterActive: 'animated slideInRight position-fixed',
        exit: 'animated',
        exitActive: 'animated slideOutLeft position-fixed',
        }

        let result_valid = false;

        if (valid) {
        result_valid = valid
        }

        this.setState({ direction: 'right', next_step: true, current_section: section, animationClass, result_valid });

        if(fixSection) {
            this.props.history.push({
                pathname: '/thinking-talents',
                state: {
                    hanndleNextQuestion: { section, valid },
                }
            })
        }
        
    

  }

  hanndlePrevQuestion() {
    const { current_step, current_section } = this.state

    const animationClass = {
      appearActive: 'animated slideInLeft position-fixed',
      enter: 'animated',
      enterActive: 'animated slideInLeft position-fixed',
      exit: 'animated',
      exitActive: 'animated slideOutRight position-fixed',
    }

    this.setState({ direction: 'left', next_step: true, animationClass });

  }

  getTalentsItems() {
    const { user } = this.props
    if(user && user?.talents) {
        let items = []
        user.talents.map((talent) => {
            return talent?.items?.map((item) => {
                items.push({...item, item_id: item.id})
            })
        })
        return items
    }
    return []
  }

  componentWillUpdate(nextProps, nextState) {
    const { direction, next_step, current_step, current_section, validations } = nextState;
    const { questions_list } = this.props

    if (current_section === 'questions') {

      if (direction === 'right' && next_step) {
        this.setState({ current_step: current_step + 1, next_step: false })
      }
      else if (direction === 'left' && next_step) {
        this.setState({ current_step: current_step - 1, next_step: false })
      }
    }

  }

  componentDidUpdate(oldProps, oldState) {

    const { current_section, validations, response_list, rearrange_first_time } = this.state;
    const { questions_list, assessment, assessment_items, currentUser } = this.props;

    if (oldState.current_section === 'report' && current_section === 'result_1') {
      this.setState({ prevState: true })
    }

    if(current_section === "questions" && !rearrange_first_time) {
        this.setState({ rearrange_first_time: true })
    }

    if ((current_section === 'result' || current_section === 'result_1' || current_section === 'rearrenge') && !validations.is_run_validate && questions_list.items.size === response_list.size) {
      this.hanndleValidations();
    }

    if ((current_section === "questions" || current_section === "start") && assessment.id && assessment.id !== DEFAULT_SESSION_ID) {
      const new_response_list = assessment_items.filter(item => item.assessment_id === assessment.id)

      let grupo_1 = []
      let grupo_2 = []
      let grupo_3 = []

      new_response_list.map(item => {
        if (item.value === "1")
          grupo_1.push(item)
        if (item.value === "2")
          grupo_2.push(item)
        if (item.value === "3")
          grupo_3.push(item)
      })

      const response_items_by_groups = {
        group_1: new List(grupo_1),
        group_2: new List(grupo_2),
        group_3: new List(grupo_3)
      }

      let next_section = 'rearrenge'

      if (current_section === "start") {
        next_section = 'report'
      }
     

      this.setState({ current_section: next_section, response_list: new_response_list, response_items_by_groups })

    }

  }
  hanndleValidations() {
    const { current_step, direction, animationClass, response_list, current_section, response_items_by_groups, result_valid } = this.state
    const { questions_list } = this.props

    let validations = {
      group_1: {
        valid: true,
        message: ""
      },
      group_2: {
        valid: true,
        message: ""
      },
      group_3: {
        valid: true,
        message: ""
      },
      valid: true,
      is_run_validate: true
    }

    /* GRUPO 1 */
    let rules = questions_list.range.get("1");

    if (rules) {
      if (response_items_by_groups.group_1.size < rules.min) {
        validations = Object.assign({}, validations, {
          group_1: {
            valid: false,
            message: `Minimum ${rules.min}`,
            error: 'min'
          },
          valid: false
        })
      }

      if (response_items_by_groups.group_1.size > rules.max) {
        validations = Object.assign({}, validations, {
          group_1: {
            valid: false,
            message: `Maximum ${rules.max}`,
            error: 'max'
          },
          valid: false
        })
      }
    }

    /* GRUPO 2 */
    rules = questions_list.range.get("2");
    if (rules) {
      if (response_items_by_groups.group_2.size < rules.min) {
        validations = Object.assign({}, validations, {
          group_2: {
            valid: false,
            message: `Minimum ${rules.min}`,
            error: 'min'
          },
          valid: false
        })
      }

      if (response_items_by_groups.group_2.size > rules.max) {
        validations = Object.assign({}, validations, {
          group_2: {
            valid: false,
            message: `Maximum ${rules.max}`,
            error: 'max'
          },
          valid: false
        })
      }
    }

    /* GRUPO 3 */
    rules = questions_list.range.get("3");
    if (rules) {
      if (response_items_by_groups.group_3.size < rules.min) {

        validations = Object.assign({}, validations, {
          group_3: {
            valid: false,
            message: `Minimum ${rules.min}`,
            error: 'min'
          },
          valid: false
        })
      }


      if (response_items_by_groups.group_3.size > rules.max) {
        validations = Object.assign({}, validations, {
          group_3: {
            valid: false,
            message: `Maximum ${rules.max}`,
            error: 'max'
          },
          valid: false
        })
      }
    }

    this.setState({ validations, response_items_by_groups })

  }

  render() {
    const { current_step, direction, animationClass, response_list, current_section, result_valid, response_items_by_groups, validations, prevState, rearrange_first_time, open_modal_confirm_lock } = this.state;
    const { displayLoadingIndicator, questions_list, user, assessment, assessment_items, fixSection, is_current_user, view_mode, animation } = this.props;

    if (displayLoadingIndicator || (user.checked && !user.id))
      return <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />;

    let question = null;
    let response_value = null;

    if (current_step) {
      if (!questions_list.items.size)
        return null;
      question = questions_list.items.get(current_step - 1)
      response_value = response_list.get(question.id);
    }

    let content = null;

    if (current_section === 'start') {
      content = <Start hanndleNextQuestion={this.hanndleNextQuestion} user={user} />
    }
    else if (current_section === "questions") {
      if (!current_step)
        return null;

      content =
        <Row key={current_step} className="content-questions">
          <Col md={{ size: 8, offset: 2 }}>
            <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 py-3 content-view d-flex flex-column">
              {false &&
                <div className="nav d-none">
                  <button className="btn btn-outline-secondary" onClick={this.hanndlePrevQuestion} disabled={current_step <= 1}>{" « Prev "}</button>&nbsp;&nbsp;
                <button className="btn btn-outline-secondary" disabled={current_step === questions_list.items.size} onClick={() => this.hanndleNextQuestion('questions')}>{" Next » "}</button>
                </div>
              }
              <div className="text-center header-questions mb-2 mb-md-4">
                <div className="paginador">{current_step}<span className="text-muted">/{questions_list.items.size}</span></div>
              </div>
              <div className="question-illustracion d-flex">
                <div className="d-inline-block mx-auto mb-2">
                  <img src={question.icon} width="130" height="130" style={{ backgroundColor: '#66b1bc' }} />
                </div>
              </div>

              <QuestionItem data={question} hanndleResponse={this.hanndleQuestionResponse} select_option={response_value} />
              
              <Disclaimers view="propiedad-intelectual" />
            </div>
          </Col>
        </Row>

    }
    else if (current_section === "ready") {
      content = <Ready key="ready" hanndleNextQuestion={this.hanndleNextQuestion} />
    }
    else if (current_section === "rearrenge") {
      content = <Rearrenge key="rearrenge" items={response_items_by_groups} response_list={response_list} validations={validations} questions={questions_list.items} response_range={questions_list.range} hanndleResponse={this.hanndleRearrengeResponse} hanndleNextQuestion={this.hanndleNextQuestion} />
    }
    else if (current_section === "result" || current_section === "result_1") {
      content = <Result key="result" validations={validations} items={response_items_by_groups} section={current_section} response_range={questions_list.range} hanndleNextQuestion={this.hanndleNextQuestion} prevState={prevState} />
      // console.log('result');
    }
    else if (current_section === "summary") {
      content = <Summary key="summary" items={response_items_by_groups.group_1} questions={questions_list.items} hanndleNextQuestion={this.hanndleNextQuestion} />
    }
    else if (current_section === "report") {
      content = <Report key="report" user={user} view_mode={view_mode} items={ is_current_user ? response_items_by_groups.group_1 : this.getTalentsItems() } quadrants={questions_list.quadrants} questions={questions_list.items} hanndleNextQuestion={this.hanndleNextQuestion} />
    }

    // console.log(current_section);
    // console.log(current_step);
    // console.log(current_section + '_' + current_step);

    return (
      <div className="view overflow-hidden">
        <ModalConfirmLock 
          isOpen={open_modal_confirm_lock}  
          close={() => this.setState({ open_modal_confirm_lock: false })}
          onAccept={() => { 
            this.save()
            this.setState({ open_modal_confirm_lock: false })
          }}
          onCancel={() => this.setState({ open_modal_confirm_lock: false })}
          blockDays={user?.assessments_block_days}
        />
        <TransitionGroup className="content">
          <CSSTransition classNames={!fixSection && animation && animationClass} timeout={{ enter: 1000, exit: 1000 }} key={current_section + '_' + current_step} appear >
            <div className="w-100">
              <Container>
                {content}
              </Container>
              {displayLoadingIndicator && <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}
//export default ThinkingTalentsPage;
//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {
  const { questions, user } = state
  const currentAssessment = getCurrentAssessmentsList(state);

  return {
    user: props.user || user,
    is_current_user: props.user ? false : true,
    view_mode: props.user ? true : false,
    questions_list: questions,
    assessment: currentAssessment,
    assessment_items: getAssessmentForCurrentAssessmentsList(state),
    displayLoadingIndicator: questions.isPending || currentAssessment.isPending,
    animation: props.animation !== undefined ? props.animation : true
  };
};

const mapDispatchToProps = {

  loadQuestions: questionsListActions.loadQuestions,
  createAssessments: assessmentsListActions.createAssessments,
  saveAssessment: assessmentsListActions.saveAssessment,
  loadAssessments: assessmentsListActions.loadAssessments,
  currentUser: userActions.currentUser,
  saveTrackingEvent: trackingActions.saveTrackingEvent
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThinkingTalentsPage);
