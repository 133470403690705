import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Switch, Route, withRouter, Redirect, useHistory, useLocation } from 'react-router-dom';
import smartlookClient from 'smartlook-client'

import session from '../core/session';
import { appActions } from '../core/app'
import { teamsListActions } from '../core/teams-list'
import { Header, Footer } from './components/common'
import { userActions } from '../core/users'
import '../assets/css/App.css';
import routes from "../routes";
import PrivateRoute from "../private-route";
import { systemCheckActions } from '../core/systemCheck/actions';
import { AccountNotFound } from './components/account-notFound/AccountNotFound';
import { PopupAcceptTerms } from './components/popups/popupAcceptTerms';
import { PopupBrokenPasswordPolicies } from './components/popups/popupBrokenPasswordPolicies';
import LoadingIndicator from './components/loading-indicator';
import { SYSTEM_ID_LOGIN_POPUPS } from '../core/constants';
import { GenericPopup } from './components/popups/GenericPopup';
import { getSystemById, systemActions } from '../core/system';

const App = React.memo(props => {
  const headerRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [openPopupAcceptTerms, setOpenPopupAcceptTerms] = useState(false);
  const [openPopupBrokenPasswordPolicies, setOpenPopupBrokenPasswordPolicies] = useState(false);
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0);
  const toggle = () => setOpenPopupAcceptTerms(!openPopupAcceptTerms);
  const togglePopupBrokenPasswordPolicie = () => setOpenPopupBrokenPasswordPolicies(!openPopupBrokenPasswordPolicies);

  const nextPopup = () => {
    setCurrentPopupIndex(currentPopupIndex+1);
}

  useEffect(() => {
    props.getProfile();
    props.loadSystemCheck();
  }, []);

  useEffect(() => {
      if(!props.user.id && history.location.pathname === '/welcome') {
        props.loadLoginPopups(SYSTEM_ID_LOGIN_POPUPS);
      }

      user && user.accept_terms !== 1 ? setOpenPopupAcceptTerms(true) : setOpenPopupAcceptTerms(false)
      user && user.must_change_pwd === 1 ? setOpenPopupBrokenPasswordPolicies(true) : setOpenPopupBrokenPasswordPolicies(false)
  }, [props.user])

  async function initializeGa() {
    //console.log(props.systemCheck.data.data)
    if(props.systemCheck.data && props.systemCheck.data.data && props.systemCheck.data.data.ga_id) {
        await ReactGA.initialize(props.systemCheck.data.data.ga_id);
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname)
        };
    }

    const initializeSmartlookCliente = () => {
        if(props.systemCheck.data && props.systemCheck.data.data && props.systemCheck.data.data.smartlook_id) {
            smartlookClient.init(props.systemCheck.data.data.smartlook_id)
        }
    }

  useEffect(() => {
    if(!window.ga) {
        initializeGa();
    }
    if(!smartlookClient.initialized()) {
        initializeSmartlookCliente();
    } else {console.log('smart listo')}
  }, [props.systemCheck.data.data]);

  useEffect(() => {
    if(props.systemCheck.data && props.systemCheck.data.data && props.systemCheck.data.data.ga_id){
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname)
    }
  }, [location]);


  const { navigateToPath, checked, authenticated, user, loadTeams, currentUser, systemCheck, thinkingTalentsOn, mindPattersOn, teamsOn } = props;
  const public_pages = ['login', 'not-found', 'signup', 'welcome', 'confirmRegister', 'contactUs', 'privacy', 'account-information-sent'];

  const searchParams = new URLSearchParams(location.search);
  const systemCheckData = systemCheck && systemCheck.data && systemCheck.data.data;
//   const refferingToken = searchParams.get('reffering')
  const invId = searchParams.get('inv-id')

  let register_allowed_invitation = systemCheckData && systemCheckData.register_allowed_invitation && systemCheckData.register_allowed_invitation === invId
  let allow_register = systemCheckData && systemCheckData.allow_register

  if (!checked) return null;

    // history.listen((location) => {
    //     ReactGA.set({ page: location.pathname });
    //     ReactGA.pageview(location.pathname)
    //   }
    // );
    


  return (
    <>

      {
        props.systemCheck.data.message === "Unauthorized" &&
        <AccountNotFound />
      }
      {
        props.systemCheck.isPending &&
        <LoadingIndicator />
      }
      {
        props.user.isPending &&
        <LoadingIndicator />
      }
      {
        props.user.id &&
        <PopupAcceptTerms isOpen={openPopupAcceptTerms} toggle={toggle} />
      }
      {
        props.user.id &&
        <PopupBrokenPasswordPolicies isOpen={props.user.must_change_pwd && openPopupBrokenPasswordPolicies} toggle={togglePopupBrokenPasswordPolicie} />
      }
      {
        !props.user.id && props.login_popups?.size > 0 && history.location.pathname === '/welcome' &&
        props.login_popups?.map((popup, index) => {
            return <GenericPopup key={popup.id} id={popup.id} isOpen={ currentPopupIndex === index } toggle={nextPopup} text={popup.text} show_command_button={popup.show_command_button} command_button_text={popup.command_button_text} command_button_link={popup.command_button_link} new_tab={popup.open_link_In_a_new_tab} />
        })
      }
      {
        props.systemCheck.data.message !== `Unauthorized` && !props.systemCheck.isPending &&
        <div
          className={`App d-flex flex-column h-100 page-${history.location.pathname.split('/')[1]}`}
          onClick={() => headerRef?.current?.globalToggleMenu()}
        >
          {
            user && authenticated &&
            <Header
              user={user}
              currentUser={currentUser}
              showTeams={teamsOn}
              loadTeams={loadTeams}
              history={history}
              ref={headerRef}
            />
          }
          <main role="main" className="flex-shrink-0">
            <Switch>
              {routes.map((route, index) => {
                const auth_required = !public_pages.includes(route.name)
                return (checked && auth_required ? (
                  <PrivateRoute authenticated={authenticated} key={index} path={route.path} exact={route.exact} name={route.name} component={props => {
                    return (
                      <route.component {...props} navigateToPath={navigateToPath} />
                    );
                  }}
                  />) : (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={props => {
                      return (
                        ((route.name === 'signup') && ((!allow_register && !register_allowed_invitation) || authenticated)) ? <Redirect to='welcome' />
                        : route.name === 'privacy' || route.name === 'contactUs' ?
                          <route.component
                            {...props}
                            navigateToPath={navigateToPath}
                            checked={checked}
                            authenticated={authenticated}
                            user={user}
                          />
                          : (authenticated ? <Redirect to='home' /> :
                            <route.component
                              {...props}
                              navigateToPath={navigateToPath}
                              checked={checked}
                              authenticated={authenticated}
                              user={user}
                            />
                          )
                      );
                    }}
                  />)
                );
              })}
            </Switch>
          </main>
          <Footer />
        </div>
      }
    </>
  )
})

App.propTypes = {
  navigateToPath: PropTypes.func.isRequired,
}

//=====================================
//  CONNECT
//-------------------------------------
const mapStateToProps = (state, props) => {
  return {
    checked: state.user.checked,
    authenticated: session.getAuthenticated(),
    user: state.user,
    history: props.history,
    systemCheck: state.systemCheck,
    thinkingTalentsOn: state.systemCheck?.data?.data?.thinking_talents_on,
    mindPattersOn: state.systemCheck?.data?.data?.mind_patterns_on,
    teamsOn: state.systemCheck?.data?.data?.teams_on,
    login_popups: getSystemById(state, SYSTEM_ID_LOGIN_POPUPS)?.items
  };
};

const mapDispatchToProps = {
  navigateToPath: appActions.navigateToPath,
  loadTeams: teamsListActions.loadTeams,
  getProfile: userActions.getProfile,
  loadSystemCheck: systemCheckActions.loadSystemCheck,
  currentUser: userActions.currentUser,
  loadLoginPopups: systemActions.loadSystem
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
