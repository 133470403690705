import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const GenericPopup = ({ isOpen, toggle, id, text, show_command_button, command_button_text, command_button_link, new_tab, exposition_frequency }) => {
    const { user } = useSelector(state => state);
    
    useEffect(() => {
        if (isOpen && exposition_frequency !== undefined) {
          const key = `user_${user.id}_popup_${id}`;

          if (exposition_frequency === 0) {
            sessionStorage.setItem(key, true);
          } 
          else if (exposition_frequency === 1) {
            localStorage.setItem(key, true);
          }
        }
      }, [isOpen, exposition_frequency, id, user?.id]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} style={{borderBottom:'0'}} ></ModalHeader>
            <ModalBody>
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
                        {
                            show_command_button === 1 && command_button_text && command_button_link &&
                            <p className="mb-0 text-truncate d-flex align-items-end">
                                <a className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-3 mx-auto" href={command_button_link} target={`${new_tab ? '_blank' : ''}`} rel="noopener noreferrer" >{command_button_text}</a>
                            </p>
                        }
            </ModalBody>
        </Modal>
    )
}