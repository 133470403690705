import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const ModalLockedAssessment = ({ isOpen, close, blockDays }) => {

    return (
        <Modal isOpen={isOpen} toggle={close}>
            <ModalHeader toggle={close} style={{ borderBottom: '0' }} ></ModalHeader>
            <ModalBody >
                <p className='text-center'>
                    {`You have already submitted your responses and they cannot be altered within ${blockDays} days of submission. For futher assistance, please reach out to us at `}
                    <a href="mailto:accounts@trueadvance.com">accounts@trueadvance.com </a>
                    or through your designated True Advance point of contact.
                </p>

                <div className="d-flex justify-content-center">
                    <button className="btn btn-primary text-uppercase rounded-pill d-inline-flex px-md-4 mr-3" onClick={close}>
                        Ok
                    </button>
                </div>
            </ModalBody>
        </Modal>
    )
}