import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const ModalConfirmLock = ({ isOpen, close, onAccept, onCancel, blockDays }) => {

    return (
        <Modal isOpen={isOpen} toggle={close}>
            <ModalHeader toggle={close} style={{ borderBottom: '0' }} >
                Complete this assessment?
            </ModalHeader>
            <ModalBody >
                <p>
                    {`Once submitted, you will not be able to edit your answers for ${blockDays} days.`}
                </p>

                <div className="d-flex justify-content-center">
                    <button className="btn btn-primary text-uppercase rounded-pill d-inline-flex px-md-4 mr-3" onClick={onAccept}>
                        Yes, submit
                    </button>
                    <button className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 ml-3" onClick={onCancel}>
                        No, go back and edit
                    </button>
                </div>
            </ModalBody>
        </Modal>
    )
}