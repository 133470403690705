import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { FinishSection } from '../../../components/questionnaires/FinishSection';
import { Question } from '../../../components/questionnaires/questions/Question';
import { StartSection } from '../../../components/questionnaires/StartSection';
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { questionnaireItemsActions } from '../../../../core/questionnaire-items/actions';
import ReactGA from 'react-ga';
import { trackingActions } from '../../../../core/tracking';
import { QuestionnaireReview } from './QuestionnaireReview';
import { LEADERSHIP_QUESTIONNAIRE_CODE } from '../../../../core/constants';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const QuestionnairePage = (params) => {
    const { id } = useParams();
    const showAnimation = params.animation !== undefined ? params.animation : true
    const dispatch = useDispatch();
    const { questionnaire_list, user } = useSelector(state => state);
    const [cuerrentSection, setCurrentSection] = useState('start');
    const [lastQuestionnaire, setLastQuestionnaire] = useState();

    useEffect(() => {
        if(id == questionnaire_list?.data?.questionnaire?.id){
            if(questionnaire_list.data?.questionnaire?.completion == 100 && cuerrentSection !== 'finish') {
                setCurrentSection('finish')
            }
        }
    }, [questionnaire_list?.data?.questionnaire])

    useEffect(() => {
        dispatch(questionnaireItemsActions.loadQuestionnaireItems(Number(id !== undefined ? id : params.id)));
    }, []);

    useEffect(() => {
        setLastQuestionnaire(questionnaire_list.data?.questionnaire ? questionnaire_list.data?.questionnaire : lastQuestionnaire)
    }, [questionnaire_list]);

    useEffect(() => {
        if(cuerrentSection === 'finish' && !questionnaire_list.isPending && questionnaire_list.data?.questionnaire?.completion != 100) {
            ReactGA.event({
                category: 'Questionnaires',
                action: `${questionnaire_list.data?.questionnaire?.name} completed`
            })
            dispatch(trackingActions.saveTrackingEvent({category: 'Questionnaires', action: `${questionnaire_list.data.questionnaire?.code || questionnaire_list.data.questionnaire?.name} completed`}))
        }
    }, [cuerrentSection, questionnaire_list.isPending]);

    const handleNextSection = (section) => {
        setCurrentSection(section)
    }


    const navigationSection = () => {
        switch (cuerrentSection) {
            case "start":
                return <StartSection
                    questionnaire_list={questionnaire_list}
                    typeQuestionnaire={questionnaire_list.data.questionnaire && questionnaire_list.data.questionnaire.code}
                    text={questionnaire_list.data.questionnaire && questionnaire_list.data.questionnaire.detail}
                    handleNextSection={handleNextSection}
                    icon={questionnaire_list.data.questionnaire && questionnaire_list.data.questionnaire.icon} />
            case "question":
                return <Question handleNextSection={handleNextSection} />
            case "finish":
                const isLeadershipQuestionnaire = questionnaire_list?.data.questionnaire?.code === LEADERSHIP_QUESTIONNAIRE_CODE;
                if(!isLeadershipQuestionnaire || (isLeadershipQuestionnaire && user?.questionnaire_datetime)) {
                    return <FinishSection questionnaireName={lastQuestionnaire?.name} />
                }
                return <QuestionnaireReview />
            default:
                return <StartSection
                    questionnaire_list={questionnaire_list}
                    typeQuestionnaire={questionnaire_list.data.questionnaire && questionnaire_list.data.questionnaire.code}
                    text={questionnaire_list.data.questionnaire && questionnaire_list.data.questionnaire.detail}
                    handleNextSection={handleNextSection}
                    icon={questionnaire_list.data.questionnaire && questionnaire_list.data.questionnaire.icon} />
        }
    }


    return (
        <>
            <div className="view overflow-hidden">
                {id == questionnaire_list?.data?.questionnaire?.id && <TransitionGroup className="content">
                    <CSSTransition classNames={showAnimation && animationClass} timeout={{ enter: 1000, exit: 1000 }} key='questionnaire' appear>
                        <div className="h-100">
                            <Container>
                                {navigationSection()}
                            </Container>
                        </div>
                    </CSSTransition>
                </TransitionGroup>}
            </div>
        </>
    )
}
