import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'reactstrap';
import { useSelector } from "react-redux";

import { LEADERSHIP_QUESTIONNAIRE_CODE } from "../../../../core/constants";

export const QuestionnaireProgress = ({ questionnaire, showName = true, customTexts = [] }) => {
    const { user } = useSelector(state => state);

    const getLinkDetails = (completion, id) => {
        const texts = [
            customTexts[0] || "Let’s start!",
            customTexts[1] || "Continue",
            customTexts[2] || "Review answers"
        ]

        const leadershipQuestionnaireUnsent = questionnaire.code === LEADERSHIP_QUESTIONNAIRE_CODE && !user.questionnaire_datetime;

        if (completion === 0) {
            return { text: texts[0], path: `/questionnaire/${id}` };
        } else if ((completion > 0 && completion < 100) || leadershipQuestionnaireUnsent) {
            return { text: texts[1], path: `/questionnaire/${id}` };
        } else if (completion === 100) {
            return { text: texts[2], path: `/questionnaire-review/${id}` };
        }
        return null;
    };

    const linkDetails = getLinkDetails(questionnaire.completion, questionnaire.id);

    return (
        <div className="w-100">
            <div>
                {showName && <p className={`text-petrol text-uppercase mb-0`}>{questionnaire.name}</p>}
                <div className="pt-1 mb-3">
                    <Progress value={questionnaire.completion} barStyle={{ backgroundColor: "#16B3BE" }} className={`${questionnaire.completion > 10 ? '' : 'questionnaire-cero'} questionnaireProgress overflow-initial`} barClassName="position-relative overflow-initial" >
                        <div className="text-right text-black ml-n3">{questionnaire.completion}%<FontAwesomeIcon icon={faCaretDown} className="ml-1" size="lg" color="#C9C7C7" /></div>
                    </Progress>
                </div>
            </div>

            {linkDetails && (
                <Link to={linkDetails.path} className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">
                    {linkDetails.text}
                </Link>
            )}
        </div>
    )
}