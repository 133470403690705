import { Record, List } from 'immutable';

export const DataStoreOptions = new Record({
  id: null,
  isNew: true,
  isPending: false,
  error: 0,
  items: new List(),
  mind_pattern: null
});
