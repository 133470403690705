import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import LoadingIndicator from '../components/loading-indicator';
import { HomeOneColumnPage } from './HomeOneColumnPage';
import { HomeTwoColumnsPage } from './HomeTwoColumnsPage';
import { FooterHomePage } from '../components/common/FooterHomePage';
import { GenericPopup } from '../components/popups/GenericPopup';


export const HomePage = () => {
  const { user } = useSelector(state => state);
  const { pdf } = useSelector(state => state);
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0);
  const nextPopup = () => {
      setCurrentPopupIndex(currentPopupIndex+1);
  }
  const resetPopupIndex = () => {
    setCurrentPopupIndex(0);
  }

  const layout_twocolumns = false;

  let layout;
  let oneColumn;
  if (layout_twocolumns) {
    layout = "two-columns";
    oneColumn = false
  } else {
    layout = "one-column";
    oneColumn = true
  }

  const popups = useMemo(() => {
    if (!user?.account?.popups) return [];

    resetPopupIndex();

    return user.account.popups.filter((popup) => {
      const { exposition_frequency, id } = popup;
      const key = `user_${user.id}_popup_${id}`;

      if (exposition_frequency === 0) { // "On each login"
        if (!sessionStorage.getItem(key)) {
          return true;
        }
        return false;
      }

      if (exposition_frequency === 1) { // "Just once and never again"
        if (!localStorage.getItem(key)) {
          return true;
        }
        return false;
      }

      if (exposition_frequency === 2) { // "On all user visits to the page"
        return true;
      }

      return false;
    });
  }, [user?.account?.popups, user?.id]);

  return (
    <>
      <div className="view overflow-hidden content-view-full w-100 d-md-flex flex-md-column">
        <Container className={`py-0 pb-md-0 ${layout_twocolumns ? "HomeTwoColumnPage" : "HomeOneColumnPage"}`} >

          {layout_twocolumns ? <HomeTwoColumnsPage layoutformat={layout} oneColumn={oneColumn} /> : <HomeOneColumnPage layoutformat={oneColumn} oneColumn={true} />}

          {
            user.accept_terms && !user.must_change_pwd && user.account && popups.map((popup, index) => {
              return <GenericPopup
                key={popup.id}
                id={popup.id}
                isOpen={currentPopupIndex === index}
                toggle={nextPopup}
                text={popup.text}
                show_command_button={popup.show_command_button}
                command_button_text={popup.command_button_text}
                command_button_link={popup.command_button_link}
                new_tab={popup.open_link_In_a_new_tab}
                exposition_frequency={popup.exposition_frequency}
              />
            })
          }

          {
            user.isPending &&
            <LoadingIndicator />
          }
          {
            pdf && pdf.isPending &&
            <LoadingIndicator />
          }

        </Container>

        <Container className="mt-auto">
          <Row>
            <Col md={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
              <FooterHomePage />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}



