import React, { useState, useCallback, useRef } from "react";
import { Button, Card, Collapse, Alert, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../core/users";

import icon_report from '../../../assets/img/boost-your-growth/your-report.svg';

export const YourReport = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state);
    const { questionnaire_resume: { isPending } } = useSelector(state => state);
    const nodeRef = useRef(null);
    const [showTip, setShowTip] = useState(false);
    const [showBtn, setShowBtn] = useState(true);
    const toggleShowTip = useCallback(() => {
        if (!user?.can_download_pdf) {
            setShowTip(prev => !prev);
        }
    }, [user]);

    const handleDownload = useCallback((e) => {
        e.stopPropagation();

        if (!user?.can_download_pdf) {
            toggleShowTip();
            return;
        }

        dispatch(userActions.fetchFullPdf(user.id));
    }, [dispatch, user, toggleShowTip, userActions]);

    return (
        <Card color="light" className={`d-flex flex-column w-100 p-3`} onClick={toggleShowTip}>
            <h2 className="mb-0 text-center text-petrol align-content-center">Your Report</h2>
            <div className="w-100 d-flex justify-content-center align-items-center my-4" >
                <img src={icon_report} alt='Your Report' width="50" height="auto" />
            </div>

            {showBtn && <Button onClick={handleDownload} disabled={isPending} className={`btn ${user?.can_download_pdf ? 'btn-primary' : 'btn-outline-tertiary disabled' } text-uppercase rounded-pill d-inline-flex px-md-2 mx-auto`}>
                {isPending
                    ?
                        <Spinner
                            color="primary"
                            className="mr-2"
                            size="sm"
                        >
                            Loading...
                        </Spinner>
                    : <i className="icon-view-report text-white mr-2"></i>
                }
                Download Full Report
            </Button>}

            <Collapse
                className="pb-0"
                isOpen={showTip && !user?.can_download_pdf}
                onEntering={() => setShowBtn(false)}
                onExited={() => setShowBtn(true)}
            >
                    <Alert
                        ref={nodeRef}
                        dismissible
                        className="mb-0"
                        color="info"
                    >
                        <Button color="link" className="p-0 close icon-close mt-1"></Button>
                        You must first complete all the assessments to access your report.
                    </Alert>
            </Collapse >

        </Card>
    )
}
