import React, { useState } from 'react';
import { InputGroup, Input } from 'reactstrap';

export const Optioncustom = (props) => {
    const { option, setValue, validText, allValid, type, locked } = props

    const [valueLength, setValueLength] = useState(option.custom_value ? option.custom_value.length : 0)

    return (
        <div className="mt-3 mr-3 mr-5">
            <InputGroup>
                <Input
                    rows={option.multiline ? '3' : ''}
                    type={option.multiline ? 'textarea' : 'label'}
                    placeholder={option.custom_title}
                    id={option.id}
                    onChange={({ target }) => {setValue(target); setValueLength(target.value.length)}}
                    defaultValue={option.custom_value}
                    maxLength={option.max_chars ? option.max_chars : '999'}
                    disabled={locked}
                />
            </InputGroup>
            {
                option.max_chars && <p align="right" style={{color: "#66b1bc"}}><small>{`${valueLength} / ${option.max_chars} characters`}</small></p>
            }
            {(!validText(option.id, option.custom_mandatory)) && !allValid &&
                <div className="text-danger mt-1">
                    <p className="mb-0">{option && option.custom_validation_text}</p>
                    <p className="mb-0">{!option || !option.custom_validation_text && "Required"}</p>
                </div>
            }
        </div>
    )
}
