import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import { MIND_PATTERNS_DATA_REPORTS, mind_pattern_sections } from '../../../core/constants';
import { mindPatternsStoreMPActions } from '../../../core/mind-patterns-storemp/actions';
import { getMindPatternsDataById } from '../../../core/mind-patterns';
import { Disclaimers } from '../common';
import { ModalLockedAssessment } from '../common/locks';
import { useDispatch } from 'react-redux';
import { trackingActions } from '../../../core/tracking';

import ReactGA from 'react-ga';

const ReportComplete = (props) => {
  const dispatch = useDispatch();
  const [ openModalLock, setOpenModalLock ] = useState(false);

  const { hanndleNext, reports, mind_pattern_id, saveMindPatternsStoreMP, user, loadMindPatternsData, mind_pattern_unsaved, changeUnsavedMindPattern, current_mind_pattern } = props;
  const item = reports.find(s_item => s_item.id === mind_pattern_id);


  useEffect(() => {
    const slides = item.report.querySelectorAll('.slide');
    slides.forEach(slide => {
      const dataContent = slide.getAttribute('data-content');
      let content = item.report.querySelector(`#${dataContent}`);

      slide.addEventListener("click", () => {
        content.classList.toggle("displayNone");
        slide.classList.toggle("open");
      })
      content.className += "displayNone";
    })
  }, [])


  if (!reports || !mind_pattern_id)
    return null


  const mind_patterns = item.code.split("").map((mp, k) => <div key={item.id + '_' + mp}
    className={`mx-1 mb-2 rounded-circle mind-pattern mind-pattern-${mp.toLowerCase()}`}><span>{mp}</span></div>)

  const handleSetNewMindPattern = (mindPattern) => {
    if(user?.has_mp_blocked){
      setOpenModalLock(true)
      return;
    }

    if(mind_pattern_unsaved && !user?.mind_pattern) {
      changeUnsavedMindPattern(mindPattern);
      hanndleNext(mind_pattern_sections.REPORT);
    }
    else {
      setThisMindPattern(mindPattern);
    }

    eventGA();
    ourTracking();
  }

  const setThisMindPattern = (reportSelected) => {
    hanndleNext(mind_pattern_sections.REPORT)
    saveMindPatternsStoreMP(reportSelected)
  }

  const eventGA = () => {
    ReactGA.event({
        category: 'Mind Patterns',
        action: 'Foreign report - set this as my mind pattern completed'
    })
  }

  const ourTracking = () => {
    dispatch(trackingActions.saveTrackingEvent({category: 'Mind Patterns', action: 'Foreign report - set this as my mind pattern completed'}))
  }

  return (
    <Row className="content-result mind-pattern-report">
      <Col lg={{ size: 8, offset: 2 }}>
        <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 py-3 content-report content-view d-flex flex-column">
  
          <div class="encabezado d-none d-md-block mb-4">
            <div class="d-flex justify-content-center"><h6 class="title-underline mb-1">Mind Patterns</h6></div>
            <span class="border border-info d-block"></span>
          </div>

          <Row>
            <Col lg={{ size: 10, offset: 1 }}>
              <div className="position-relative">
                <a className="cursor-pointer icon-back" onClick={() => hanndleNext(mind_pattern_sections.REPORT)}><FontAwesomeIcon icon={faArrowLeft} /></a>
                <h1 className="text-center d-md-none">Mind Patterns</h1>
                <h5 className="text-reset text-center mb-3">{item.title}</h5>
              </div>

              <div className="d-flex justify-content-center">
                {mind_patterns}
              </div>

              <hr />

              <div className="pb-5 pb-md-0 mind-report-complete">
                <div ref={el => item.report = el} dangerouslySetInnerHTML={{ __html: item.report }}></div>
              </div>
              
                <div className="d-flex justify-content-center align-content-center">
                    <button onClick={() => hanndleNext(mind_pattern_sections.REPORT)} className="btn btn-outline-primary text-uppercase rounded-pill px-md-4"> {"<< Go Back"} </button>
                    
                    {current_mind_pattern !== item.code &&
                      <>
                        <button onClick={() => { handleSetNewMindPattern(item.code) }} className={`btn btn-outline-tertiary ${user?.has_mp_blocked ? 'disabled' : ''} text-uppercase rounded-pill px-md-4 ml-2`}>Set this as my mind pattern</button>
                        <ModalLockedAssessment 
                          isOpen={openModalLock}  
                          close={() => setOpenModalLock(false) }
                          blockDays={user?.assessments_block_days}
                        />
                      </>
                    }
                </div>
              
              <Disclaimers view="copyright" />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>

  )
}

//=====================================
//  CONNECT

const mapStateToProps = (state, props) => {
  const { mind_patterns_store_mp, user } = state;
  const reports = getMindPatternsDataById(state, MIND_PATTERNS_DATA_REPORTS)
  return {
    mindPatternsStoreMP: mind_patterns_store_mp,
    user,
    reports: reports.items
  };
};

const mapDispatchToProps = {
  saveMindPatternsStoreMP: mindPatternsStoreMPActions.mindPatternsSaveStoreMp,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportComplete);

